@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "sora";
  src: url("./sora.ttf");
}

@font-face {
  font-family: "helvetica";
  src: url("./Helvetica.ttf");
}

